








// CORE
import { Component } from 'vue-property-decorator'

// MIXINS
import NotifyMixin from '@/mixins/NotifyMixin'

// COMPONENTS
import DrawerHeader from '@/components/drawers/DrawerHeader.vue'
import RecoveryPasswordEmailForm from '@/components/forms/RecoveryPasswordEmailForm.vue'

// STORE
import AuthModule from '@/store/modules/auth'

@Component({
  components: {
    DrawerHeader,
    RecoveryPasswordEmailForm,
  },
})
export default class EmailForRecovery extends NotifyMixin {
  private handleSubmit (email: string) {
    AuthModule.beginResetPassword(email)
      .then(() => {
        this.notifySuccess('Письмо, с ссылкой для восстановления пароля, отправлено')
      })
      .catch(error => {
        this.notifyError(error)
      })
  }
}
